var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'sticky-action-toolbar': _vm.sticky
    },
    staticStyle: {
      "background": "black"
    },
    style: _vm.actionToolbarStyle
  }, [_c('div', {
    staticClass: "action-bar-content-container"
  }, [_c('div', {
    staticClass: "selected-units-display"
  }, [_c('p', {
    staticStyle: {
      "font-size": "10px",
      "line-height": "16px",
      "font-weight": "600",
      "font-family": "Inter, serif"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedCounter + " " + _vm.$t("Selected")) + " ")]), _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("../../assets/images/close_red_12_12.svg")
    },
    on: {
      "click": _vm.clearSelected
    }
  })]), _c('div', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("•")]), _c('BaseButton', {
    attrs: {
      "label": _vm.$t('Delete'),
      "primary": true
    },
    on: {
      "click": _vm.deleteButton
    }
  })], 1), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "custom-message": _vm.customDeleteMessage,
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.emitDelete
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }