<template>
  <div
    :style="actionToolbarStyle"
    :class="{ 'sticky-action-toolbar': sticky }"
    style="background: black"
  >
    <div class="action-bar-content-container">
      <div class="selected-units-display">
        <p
          style="
            font-size: 10px;
            line-height: 16px;
            font-weight: 600;
            font-family: Inter, serif;
          "
        >
          {{ selectedCounter + " " + $t("Selected") }}
        </p>
        <img
          style="cursor: pointer"
          @click="clearSelected"
          src="../../assets/images/close_red_12_12.svg"
        />
      </div>
      <div style="color: white">•</div>
      <BaseButton @click="deleteButton" :label="$t('Delete')" :primary="true" />
    </div>
    <DeleteModal
      :custom-message="customDeleteMessage"
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="emitDelete"
      v-if="isDeleteModalOpen"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/common/BaseButton";
import DeleteModal from "@/components/common/DeleteModal";

export default {
  name: "UnitActionToolbar",
  components: { BaseButton, DeleteModal },
  props: ["selectedCounter", "module", "customDeleteMessage"],
  data() {
    return {
      mainToolbar: null,
      stickyThreshold: null,
      sticky: false,
      content: null,
      isDeleteModalOpen: false,
      actionToolbarStyle: {
        width: "100%",
        zIndex: 2,
      },
    };
  },
  mounted() {
    this.mainToolbar = document.getElementById("toolbar-main");
    this.stickyThreshold =
      window.pageYOffset + this.mainToolbar.getBoundingClientRect().top;
    this.content = document.getElementById(this.module + "-content");
    window.addEventListener("scroll", this.toolBarToSticky);
    console.log(this.customDeleteMessage);
  },
  beforeDestroy() {
    // same amount as header margin
    if (this.mainToolbar.classList.contains("sticky")) {
      this.content.style.marginTop = "66px";
    } else {
      this.content.style.marginTop = "0px";
    }
    window.removeEventListener("scroll", this.toolBarToSticky);
  },
  methods: {
    toolBarToSticky() {
      if (this.mainToolbar.classList.contains("sticky")) {
        this.sticky = true;
        this.actionToolbarStyle.marginTop = "66px";
        this.actionToolbarStyle.position = "fixed";
        this.actionToolbarStyle.top = "0";
        this.actionToolbarStyle.left = "0";
        this.content.style.marginTop = "130px";
      } else {
        this.sticky = false;
        this.actionToolbarStyle.marginTop = "0px";
        this.actionToolbarStyle.position = "unset";
        this.actionToolbarStyle.top = "unset";
        this.actionToolbarStyle.left = "unset";
      }
    },
    clearSelected() {
      this.$emit("clearSelection");
    },
    deleteButton() {
      this.isDeleteModalOpen = true;
    },
    emitDelete() {
      this.isDeleteModalOpen = false;
      this.$emit("deleteSelected");
    },
  },
};
</script>

<style scoped>
.action-bar-content-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 16px 48px;
  gap: 16px;
}

.selected-units-display {
  display: flex;
  flex-wrap: nowrap;
  background: white;
  color: black;
  height: 24px;
  margin: 4px 0;
  padding: 4px 8px;
  gap: 4px;
}
</style>
