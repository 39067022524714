<template>
  <div>
    <Header
      id="property-header"
      :property-name="propertyName"
      @addBuilding="addNewBuilding()"
      @addUnit="addNewUnit()"
      @addContract="addNewContract()"
      :selected-units="selectedUnits"
    />
    <ActionToolbar
        :module="'property'"
        v-if="selectedUnits.ids.length > 0"
        :selected-counter="selectedUnits.ids.length"
        :custom-delete-message="customMessage"
        @clearSelection="clearSelectedUnits"
        @deleteSelected="deleteSelectedUnits"
    />
    <GeneralView
      :add-building="addNewBuildingBool"
      :add-unit="addNewUnitBool"
      :add-contract="addNewContractBool"
      :clear-selected-units="clearSelectedUnitsUpdater"
      :delete-selected-units="deleteSelectedUnitsUpdater"
      @closeBuilding="closeNewBuilding()"
      @closeUnit="closeNewUnit()"
      @closeContract="closeNewContract()"
      @selectedUnits="setSelectedUnits"
    />
    <!--  <router-view/> -->
  </div>
</template>

<script>
import Header from "../../components/PropertyOwner/Detail/Header.vue";
import axios from "axios";
import GeneralView from "@/components/PropertyOwner/Detail/GeneralView.vue";
import ActionToolbar from "@/components/PropertyOwner/ActionToolbar";

export default {
  name: "PropertyDetail",
  components: {
    Header,
    GeneralView,
    ActionToolbar
  },
  data() {
    return {
      propertyName: "",
      customMessage: "",
      addNewBuildingBool: false,
      addNewUnitBool: false,
      addNewContractBool: false,
      selectedUnits: {
        ids: [],
        contractPresent: false,
      },
      clearSelectedUnitsUpdater: 0,
      deleteSelectedUnitsUpdater: 0
    };
  },
  async created() {
    let chosenObjectId = this.$route.params.id;
    await Promise.all([this.getProperty(chosenObjectId)]);
  },
  mounted() {},
  computed: {},
  methods: {
    async getProperty(chosenObjectId) {
      const response = await axios.get(`/api/property/${chosenObjectId}`);
      console.log("RESPONSE", response.data);
      this.propertyName = response.data.name;
    },
    addNewBuilding() {
      this.addNewBuildingBool = true;
    },
    addNewUnit() {
      this.addNewUnitBool = true;
    },
    addNewContract() {
      this.addNewContractBool = true;
    },
    closeNewBuilding() {
      this.addNewBuildingBool = false;
    },
    closeNewUnit() {
      this.addNewUnitBool = false;
    },
    closeNewContract() {
      this.addNewContractBool = false;
    },
    setSelectedUnits(value) {
      if (value.contractPresent) {
        this.customMessage = this.$t('UnitHasContractAttached')
      } else {
        this.customMessage = ""
      }
      console.log(this.customMessage);
      console.log(value);
      this.selectedUnits = value;
    },
    clearSelectedUnits() {
      this.selectedUnits = {
        ids: [],
        contractPresent: false,
      }
      this.clearSelectedUnitsUpdater++;
    },
    deleteSelectedUnits() {
      this.selectedUnits = {
        ids: [],
        contractPresent: false,
      }
      this.deleteSelectedUnitsUpdater++;
    }
  },
};
</script>
<style lang="scss" scoped>
.slide-animation-open {
  margin-left: 256px !important;
}
.slide-animation-close {
  margin-left: 0 !important;
}
#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}
</style>
