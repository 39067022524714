<template>
  <div
    class="header"
    style="background-color: white; padding-top: 0px; width: 100%"
  >
    <v-row class="title-row" style="padding-top: 8px; padding-bottom: 24px">
      <v-col style="gap: 4px; display: grid">
        <h4 v-if="propertyName" class="header-title">
          {{ propertyName }}
        </h4>
      </v-col>
    </v-row>
    <HeaderToolbar :top-margin="'66px'" :module="'property'">
      <!--  <button
        name="property-button"
        @click="openDropdown()"
        class="outlined-small-button"
        style="background-color: black !important; border:0px"
      >
        <img
          src="@/assets/images/plusSign.svg"
          style="
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(103%) contrast(102%);
          "
          alt=""
        />
        <p
          style="
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(103%) contrast(102%);
          "
        >
          {{ $t("Add") }}
        </p>
      </button> -->
      <IconDropdown
        :items="additional_activities"
        :display-key="'name'"
        :value-key="'value'"
        :icon="'plusSign.svg'"
        @itemSelected="selectActivity"
        :property-view="true"
      />
      <img class="point-separator" src="@/assets/images/•.svg" alt="" />

      <div style="margin-left: 0px">
        <v-text-field
          autocomplete="off"
          outlined
          v-model="search"
          hide-details
          :id="'search-bar'"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search') + '...'"
          style="background: white"
        >
        </v-text-field>
      </div>
      <div>
        <button
          name="property-button"
          @click="shareSomething()"
          class="outlined-small-button"
          style="
            width: 86px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <img src="@/assets/images/share16x16.svg" alt="" />
          <p>
            {{ $t("Export") }}
          </p>
        </button>
      </div>
    </HeaderToolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import IconDropdown from "@/components/common/IconDropdown";
import nanoid from "nanoid";

export default {
  name: "Header",
  components: {
    HeaderToolbar,
    IconDropdown,
  },
  props: ["propertyName"],
  data() {
    return {
      documentTemplates: [
        {
          name: this.$t("EITEA"),
          value: "mechanical_in_and_outtake",
        },
        { name: this.$t("TEAN"), value: "gravitational" },
        { name: this.$t("MUU"), value: "other" },
      ],
      activeView: "generalView",
      additional_activities: [
        { name: this.$t("Building"), value: "add_building", _id: nanoid(8) },
        {
          name: this.$t("Unit"),
          value: "add_unit",
          _id: nanoid(8),
        },
        {
          name: this.$t("Contract"),
          value: "add_contract",
          _id: nanoid(8),
        },
      ],
    };
  },
  created() {
    console.log("route: ", this.$route);
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    ...mapActions([
      "changeSidepanelStateProperties",
      "changeSidepanelStateProperty",
    ]),
    async selectActivity(activity) {
      if (activity.value === "add_building") {
        this.$emit('addBuilding')
      }
      if (activity.value === "add_unit") {
        this.$emit("addUnit")
      }
      if (activity.value === "add_contract") {
        this.$emit('addContract')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  .v-list-item--link {
    .theme--light div {
      display: flex;
    }
  }
}

.col {
  padding: 0;
}
.left-row {
  justify-content: flex-start;
  .v-select {
    max-width: 250px;
  }
  .sector-type {
    margin-right: 42px;
  }
}
.right-row {
  justify-content: flex-end;
  .autocomplete {
    max-width: 300px;
  }
  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}
.icon-button-active {
  background-color: #f4f5f7;
}

#generalView {
  padding-bottom: 22px;
}
#listingsView,
#unitsView {
  margin-left: 24px;
  padding-bottom: 22px;
}
#leadsView {
  margin-left: 24px;
  padding-bottom: 22px;
}
#listingsView,
#generalView,
#unitsView,
#leadsView {
  cursor: pointer;
}
.active-view {
  border-bottom: 1px solid black;
}
::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}
::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}
::v-deep .v-icon.v-icon {
  font-size: 18px;
}
::v-deep .theme--light.v-icon {
  color: black;
}
::v-deep .v-text-field__slot input {
  font: revert !important;
}
</style>
