var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white",
      "padding-top": "0px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "title-row",
    staticStyle: {
      "padding-top": "8px",
      "padding-bottom": "24px"
    }
  }, [_c('v-col', {
    staticStyle: {
      "gap": "4px",
      "display": "grid"
    }
  }, [_vm.propertyName ? _c('h4', {
    staticClass: "header-title"
  }, [_vm._v(" " + _vm._s(_vm.propertyName) + " ")]) : _vm._e()])], 1), _c('HeaderToolbar', {
    attrs: {
      "top-margin": '66px',
      "module": 'property'
    }
  }, [_c('IconDropdown', {
    attrs: {
      "items": _vm.additional_activities,
      "display-key": 'name',
      "value-key": 'value',
      "icon": 'plusSign.svg',
      "property-view": true
    },
    on: {
      "itemSelected": _vm.selectActivity
    }
  }), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('div', [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "width": "86px",
      "height": "32px",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    attrs: {
      "name": "property-button"
    },
    on: {
      "click": function ($event) {
        return _vm.shareSomething();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/share16x16.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t("Export")) + " ")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }