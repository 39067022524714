var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "id": "property-header",
      "property-name": _vm.propertyName,
      "selected-units": _vm.selectedUnits
    },
    on: {
      "addBuilding": function ($event) {
        return _vm.addNewBuilding();
      },
      "addUnit": function ($event) {
        return _vm.addNewUnit();
      },
      "addContract": function ($event) {
        return _vm.addNewContract();
      }
    }
  }), _vm.selectedUnits.ids.length > 0 ? _c('ActionToolbar', {
    attrs: {
      "module": 'property',
      "selected-counter": _vm.selectedUnits.ids.length,
      "custom-delete-message": _vm.customMessage
    },
    on: {
      "clearSelection": _vm.clearSelectedUnits,
      "deleteSelected": _vm.deleteSelectedUnits
    }
  }) : _vm._e(), _c('GeneralView', {
    attrs: {
      "add-building": _vm.addNewBuildingBool,
      "add-unit": _vm.addNewUnitBool,
      "add-contract": _vm.addNewContractBool,
      "clear-selected-units": _vm.clearSelectedUnitsUpdater,
      "delete-selected-units": _vm.deleteSelectedUnitsUpdater
    },
    on: {
      "closeBuilding": function ($event) {
        return _vm.closeNewBuilding();
      },
      "closeUnit": function ($event) {
        return _vm.closeNewUnit();
      },
      "closeContract": function ($event) {
        return _vm.closeNewContract();
      },
      "selectedUnits": _vm.setSelectedUnits
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }